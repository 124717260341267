const awsconfig = {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_kyCsQBpNo',
      userPoolWebClientId: '5mkblpfcvv1n7nqhlvk2aqr2ra',
      oauth: {
        domain: 'internal-app-tool.auth.us-east-1.amazoncognito.com',
        scope: [
          'email','phone','openid', 'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: 'https://internal-tool.daffodilhealth.co/',
        redirectSignOut: 'https://internal-tool.daffodilhealth.co/',
        // redirectSignIn: 'http://localhost:3000/',
        // redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  };
  
  export default awsconfig;